// import setBeforeAfterSlider from "./_modules/setBeforeAfterSlider";
// import setDrSlider from "./_modules/setDrSlider";
// import setFaqToggle from "./_modules/setFaqToggle";
// import setPhotoSlider from "./_modules/setPhotoSlider";
// import setAccordion from "./_modules/setAccordion";
import photoSwiper from "./_modules/setModalSwiper.js";
import setModal from "./_modules/setModal.js";

window.addEventListener('load', () => {
  // setBeforeAfterSlider();
  // setDrSlider();
  // setFaqToggle();
  // setPhotoSlider();
  // setAccordion();
  photoSwiper();
  setModal();
})