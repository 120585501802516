import Swiper from 'swiper';
import { Navigation, Autoplay, Lazy } from 'swiper';

const photoSwiper = () => {
   const modal = document.getElementById("modal");
   const open = document.querySelectorAll(".js-open-modal");
   const close = document.querySelectorAll(".modal-close ");

   const swiper = new Swiper(".swiper", {
      modules: [Navigation, Autoplay, Lazy],
      loop: true,
      navigation: {
         nextEl: ".swiper-button-next",
         prevEl: ".swiper-button-prev",
      },
   });
   open.forEach((el) => {
      el.addEventListener("click", () => {
         const modalIndex = el.dataset.slideIndex;
         swiper.slideTo(modalIndex);
         modal.classList.add("is-active");
      });
   });

   close.forEach((closeModalBtn) => {
      closeModalBtn.addEventListener("click", () => {
         modal.classList.remove("is-active");
      });
   });
}

export default photoSwiper