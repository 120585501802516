const setModal = () => {
  const body = document.querySelector('body');

  function openModal(trigger, target, index) {
    target.classList.add('is-open');
    body.classList.add('no-scroll');
  }

  function closeModal(trigger, target) {
    target.classList.remove('is-open');
    body.classList.remove('no-scroll');
  }

  const triggers = document.querySelectorAll('.js-modal-trigger');

  triggers.forEach((trigger, index) => {
    const contentsId = trigger.getAttribute('modal-content');
    const target = document.getElementById(contentsId);
    const closeBtn = target.querySelectorAll('.js-modal-close');
    const modalBg = target.querySelector('.modal-bg');

    trigger.addEventListener('click', function (e) {
      e.preventDefault();
      openModal(trigger, target, index);
    });

    closeBtn.forEach((el) => {
      el.addEventListener('click', function () {
        closeModal(trigger, target);
      })
    })

    modalBg.addEventListener('click', function () {
      closeModal(trigger, target);
    });
  })
}

export default setModal;